
.component-message-button {
  @import '@/assets/style/middle.scss';
  @import '@/assets/style/trans.scss';

  position: fixed;
  right: 0.5em;
  bottom: 1em;
  z-index: 1000;
  display: inline-table;

  .chat-button {
    @include middle();
    @include trans();
    width: 2.2em;
    height: 2.2em;
    background-color: #fff;
    cursor: pointer;
    font-size: 1.8em;

    // &:hover {
    //   box-shadow: 0 5px 24px rgba(0, 0, 0, 0.3);
    // }
  }

  .main-button {
    @include trans(0.1s);
    @extend .chat-button;
    border-radius: 50%;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
    position: relative;
    background: #a2abb2 url(../assets/img/msg.png) no-repeat center/80%;
    color: #fff;
    z-index: 2;

    &:hover {
      // background-color: #fff;
      box-shadow: 0 5px 24px rgba(0, 0, 0, 0.3);
    }

    &.active, {
      background: #a2abb2 url(../assets/img/xmark.png) no-repeat center/80%;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    &:active {
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    }

    &:focus {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .message-buttons {
    @include trans(.5s);
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    display: contents;
    z-index: 1;
    border-radius: 50%;

    > .chat-button {
      pointer-events: none;
      opacity: 0;
      margin-bottom: -2.2em;
      color: #000;
    }

    &.active {
      > .chat-button {
        pointer-events: all;
        opacity: 1;
        margin-bottom: 0.5em;
      }
    }
  }

  // tooltip
  .tooltip {
    @include trans(0.5s);
    opacity: 0;
    $color: #000;
    position: absolute;
    z-index: 10;

    &.active {
      opacity: 1;
    }

    .arrow,
    .arrow::before,
    .arrow::after {
      position: absolute;
      width: 0.75em;
      height: 0.75em;
      background: inherit;
    }

    .content {
      background-color: $color;
      opacity: 0.8;
      position: relative;
      padding: 0.25em 0.5em;
      border-radius: 4px;
      color: #fff;
      z-index: 2;
      font-size: 0.875em;
      white-space: nowrap;
    }

    .arrow {
      z-index: -1;
      visibility: hidden;
      background-color: $color;
      opacity: 0.7;
      right: 1.5em;

      &:after, &:before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
      }

      &:after {
        background-color: #ccc;
        left: -2px;
      }

      &:before {
        z-index: 2;
      }
    }

    // &[data-popper-placement^='top'] {
      top: -1.75em;
      right: 0.25em;
      .arrow {
        bottom: -.25em;
      }
    // }
  }
}
