
@import "@/stylesheets/_function.sass"

.room-card__brief
  @apply relative
  &__gallery
    // width: 420px
    @apply border-1 flex-no-shrink
    height: fit-content
    // @screen md
    //   width: 50%
    // @screen lg
    //   @apply w-full
    // @screen xl
    //   width: 50%
    &__cover
      padding-top: imageRatio(400, 270)
      &:hover .room-card__brief__gallery__cover__mask
        @apply opacity-100
        i
          @apply opacity-100
      &__mask
        @apply absolute w-full h-full flex items-center justify-center opacity-0 cursor-pointer
        top: 0
        left: 0
        transition: opacity 0.3s ease-out
        background: radial-gradient(ellipse at center, rgba(0,0,0,0.20) 0%,rgba(0,0,0,0.55) 100%)
        i
          @apply text-white text-6xl opacity-0
          transition: opacity 0.3s ease-out
  &__content__price-tag
    @apply border-grey-lighter text-xs cursor-default #{!important}
    right: 1.5rem
    top: 0px

  &__content__row
    @apply text-sm
    &:not(:last-of-type)
      .content-group
        @apply mb-24
    .content-group
      label
        @apply text-grey-dark block mb-4
      span, div
        @apply text-grey-darker

    .feature-list
      padding-left: 0rem
      list-style-type: none
      @apply w-full
      li
        @apply mb-4 text-grey-darker
        .room-card__feature-icon
          height: 18px
          width: 18px

    .more-features
      span, i
        @apply text-blue
