
.word-break-word
  word-break: break-word

.hotel-contact__row
  a
    @apply no-underline text-grey-darkest

.feature__icon
  width: 22px
  &-charge
    width: 22px
    filter: invert(56%) sepia(68%) saturate(2612%) hue-rotate(356deg) brightness(92%) contrast(91%)

