
.remove-plan
  @apply text-grey-light cursor-pointer
//   top: 8px
//   right: 8px

.order-plan
  &:not(:last-child)
    @apply border-b-1
  &__row
    @apply text-sm flex justify-between items-start mb-8
    label
      @apply mr-8 leading-tight
    span:not(.os-tag)
      @apply flex-no-shrink leading-tight text-grey-darkest

