
.toggle
  @apply relative flex items-center
  &__input
    @apply hidden
  &__core
    @apply relative cursor-pointer
    width: 44px
    height: 22px
    border-radius: 16px
    background-color: #e5e5e5
    transition: all 0.3s
    &::before
      content: ''
      position: absolute
      top: 50%
      left: 27%
      transform: translate(-50%, -50%)
      width: 18px
      height: 18px
      border-radius: 50%
      background-color: #fff
      transition: all 0.3s
    &--checked
      background-color: #078ABC
      &::before
        left: 73%
    @screen sm
      width: 28px
      height: 16px
      &::before
        width: 12px
        height: 12px
        left: 29%
      &--checked
        &::before
          left: 73%
