@import '@/stylesheets/bs_func.scss';

// layout
.row {
  @include row();
}

.col-auto {
  @include col-auto();
}

.col {
  @include col();
}

.d-none {
  @include d-none();
}

.d-flex {
  @include d-flex();
}

@for $i from 1 through 12 {
  $val: $i / 12;

  .col-#{$i} {
    @include col-val($val);
  }
}

$width: sm, md, lg, xl;
$width1: 576, 768, 992, 1200;

@for $i from 1 through length($width) {
  $w: nth($width, $i);
  $w1: nth($width1, $i);

  @media (min-width: #{$w1}px) {
    .col-#{$w}-auto {
      @include col-auto();
    }
    
    .col-#{$w} {
      @include col();
    }

    @for $i from 1 through 12 {
      $val: $i / 12;
    
      .col-#{$w}-#{$i} {
        @include col-val($val);
      }
    }

    .d-#{$w}-flex {
      @include d-flex();
    }

    .d-#{$w}-none {
      @include d-none();
    }
  }
}

// button
.btn-outline-grey {
  $color: #adb4ba;
  $color1: #e6eaed;

  background-color: #fff;
  border: 1px solid $color;
  color: #393d46;

  &:hover {
    background-color: $color1;
  }
}

.btn-primary {
  color: #fff;
  background-color: #3AB3E2;
  border: 1px solid #3AB3E2;
}
