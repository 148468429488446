
.order-total
  z-index: 9
  // top: 120px
  &__desktop
    &__body
      @apply overflow-y-scroll
      -webkit-overflow-scrolling: touch
      // max-height: 400px
    &__footer
      &__row
        @apply flex justify-between
        &:not(:last-of-type)
          @apply mb-16

  &__mobile
    @apply fixed pin-b pin-l bg-white w-full z-10
    box-shadow: 0 -15px 30px -10px rgba(92, 101, 107, 0.15)
    transform-orign: center bottom
    &__btn-group
      .btn-solid
        width: 170px
  .isAdding
    animation: isAdding .3s ease
  .isMinus
    animation: isMinus .3s ease
@keyframes isAdding
  0%
    transform: scale(1)
  50%
    transform: scale(1.05)
  100%
    transform: scale(1)
@keyframes isMinus
  0%
    transform: scale(1)
  50%
    transform: scale(0.95)
  100%
    transform: scale(1)
