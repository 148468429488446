@mixin middle($x:center, $y:center) {
  display: flex;
  justify-content: #{$x};
  align-items: #{$y};
}

.middle {
  @include middle();
}

$middle-x-list: left, right, middle;
$middle-x-list-val: flex-start, flex-end, center;

$middle-y-list: top, bottom, middle;
$middle-y-list-val: flex-start, flex-end, center;

@for $i from 1 through length($middle-x-list) {
  $x: nth($middle-x-list, $i);
  $x-val: nth($middle-x-list-val, $i);

  @for $j from 1 through length($middle-y-list) {
    $y: nth($middle-y-list, $j);
    $y-val: nth($middle-y-list-val, $j);
    
    .#{$x}-#{$y} {
      @include middle($x-val, $y-val);
    }    
  }
}