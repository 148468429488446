
.hotel-rooms.container {
  position: relative;

  &.show {
    z-index: 11;

    .hotel__body__total {
      z-index: 1;
    }
  }

  .hotel__body__rooms {
    z-index: 2;
  }

  .hotel__body__total {
    z-index: 2;
  }
}
