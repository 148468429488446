
.wrapper
  @apply relative
  &__pagination
    @apply absolute text-white bg-grey-darker opacity-50 rounded-sm text-sm
    right: 4px
    bottom: 4px
    padding: 2px 4px
  &__btn-block
    @apply absolute h-full flex flex-col items-center
    width: 40px
    &--next
      right: 0
    &--left
      right: 0
  &__btn
    @apply text-grey-darker bg-white rounded-full opacity-50
    height: 30px
    width: 30px
    padding: 4px
    &--disabled
      @apply opacity-25
.image-swiper
  @apply w-full mx-auto flex overflow-x-auto
  scroll-snap-type: x mandatory
  scroll-snap-stop: always
  &::-webkit-scrollbar
    display: none
  &__item
    @apply w-full bg-cover bg-center bg-no-repeat
    height:254px
    flex-shrink: 0
    scroll-snap-align: start
