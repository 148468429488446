@for $i from 1 through 100 {
  .w-#{$i} {
    width: percentage($i/100) !important;
  }

  .h-#{$i} {
    height: percentage($i/100) !important;
  }
}

@media (min-width: 1200px) {
  @for $i from 1 through 100 {
    .w-xl-#{$i} {
      width: percentage($i/100) !important;
    }

    .h-xl-#{$i} {
      height: percentage($i/100) !important;
    }
  }
}

@media (min-width: 992px) {
  @for $i from 1 through 100 {
    .w-lg-#{$i} {
      width: percentage($i/100) !important;
    }

    .h-lg-#{$i} {
      height: percentage($i/100) !important;
    }
  }
}