
.modal__mobile-orders
  &__body
    height: calc(100vh - 165px)
    overflow-y: scroll
    -webkit-overflow-scrolling: touch
    &__total__row
      @apply flex justify-between
      &:not(:last-of-type)
        @apply mb-16
  &__footer
    @apply p-16 fixed pin-b pin-l bg-white w-full z-20
    box-shadow: 0 -15px 30px -10px rgba(92, 101, 107, 0.15)
