.nav-dropdown
  @apply relative flex items-stretch h-full list-reset
  &__display
    @apply flex content-center items-center cursor-pointer
  &__list
    @apply absolute bg-white shadow-lg rounded-b-sm hidden overflow-hidden
    z-index: 11
    min-width: 133px
    top: 100%
    // left: -50%
    transition: all 0.3s ease
    li
      @apply p-16 text-center cursor-pointer
      &:hover
        @apply bg-grey-lightest
      &.actived
        @apply bg-blue text-white
  &:hover
    & > .nav-dropdown__list
      @apply block
    a
      @apply text-grey-darker w-full h-full
      li
        @apply w-full