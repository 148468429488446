
.remove-plan
  @apply text-grey-light cursor-pointer

.order-addon
  &:not(:last-child)
    @apply border-b-1
  &__row
    @apply text-sm flex items-start mb-8
    label
      @apply mr-8 leading-tight
    span
      @apply flex-no-shrink leading-tight
.removeAddonBtn
  @apply ml-8 text-grey-dark outline-none
  i
    @apply text-grey-light ease
  &:hover i
    @apply text-blue
