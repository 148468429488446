
body
  @apply antialiased
  font-family: 'Microsoft JhengHei', sans-serif

.os-tag
  margin-top: 0px !important

footer
  .footer
    &__hotel-name
      i
        @apply inline-block
        transition: .3s ease
      @media not all and (pointer: coarse)
        .footer__hotel-website:hover
          i
            transform: translateX(5px)

    &__contacts
      &__way
        @apply text-grey-darker no-underline inline-flex items-center
        &:not(:last-of-type)
          @apply mb-16
        i
          @apply mr-8 text-lg

  &.mobile-has-order
    margin-bottom: 60px

button, .btn
  touch-action: manipulation

.fb_dialog_content > iframe
  display: none !important
.fb-customerchat, .fb_dialog_content
  display: none !important
.fb_iframe_widget, .fb_invisible_flow
  iframe
    bottom: 72px !important
    @screen sm
      bottom: 4px !important
      right: 4px !important
