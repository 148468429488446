// layout
@mixin row() {
  display: flex;
  flex-wrap: wrap;
  // margin-right: -15px;
  // margin-left: -15px;
}

@mixin col-val($val) {
  flex: 0 0 percentage($val);
  max-width: percentage($val);
}

@mixin col-auto() {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

@mixin col() {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

@mixin d-none() {
  display: none !important;
}

@mixin d-flex() {
  display: -ms-flexbox !important;
  display: flex !important;
}
