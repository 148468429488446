$padding-list: 
  0.25rem, 0.5rem, 1rem, 1.5rem, 3rem, 3.5rem,// bootstrap
  4rem, 5rem, 6rem //customize
  ;
  
@for $i from 1 through length($padding-list) {
  $val: nth($padding-list, $i);

  .p-#{$i} {
    padding: $val;
  }

  .pt-#{$i} {
    padding-top: $val;
  }
  
  .pb-#{$i} {
    padding-bottom: $val;
  }

  .pl-#{$i} {
    padding-left: $val;
  }
  
  .pr-#{$i} {
    padding-right: $val;
  }

  // lg
  @media (min-width: 991px){
    .p-lg-#{$i} {
      padding: $val !important;
    }

    .pt-lg-#{$i} {
      padding-top: $val;
    }
    
    .pb-lg-#{$i} {
      padding-bottom: $val;
    }

    .pl-lg-#{$i} {
      padding-left: $val !important;
    }

    .pr-lg-#{$i} {
      padding-right: $val !important;
    }
  }
}