
#cookie-notification
  @apply text-sm text-white fixed pin-b pin-l w-full leading-normal py-8
  z-index: 1010
  background: rgba(0, 0, 0, .75)
  .content
    a
      @apply text-white underline
  button
    @apply bg-blue-light text-sm
    letter-spacing: .1rem
    &:hover
      @apply bg-blue text-white
