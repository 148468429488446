
@import "@/stylesheets/_function.sass"

.building-card
  &__brief
    @apply relative
    &__gallery
      // width: 420px
      @apply flex-no-shrink
      // @screen md
      //   width: 50%
      // @screen lg
      //   @apply w-full
      // @screen xl
      //   width: 50%
      &__cover
        padding-top: imageRatio(400, 270)
        &:hover .room-card__brief__gallery__cover__mask
          @apply opacity-100
          i
            @apply opacity-100
        &__mask
          @apply absolute w-full h-full flex items-center justify-center opacity-0 cursor-pointer
          top: 0
          left: 0
          transition: opacity 0.3s ease-out
          background: radial-gradient(ellipse at center, rgba(0,0,0,0.20) 0%,rgba(0,0,0,0.55) 100%)
          i
            @apply text-white text-6xl opacity-0
            transition: opacity 0.3s ease-out
    &__content__row
      @apply text-sm
      .content-group
        @apply mb-24
        label
          @apply text-grey-dark block mb-4
        span
          @apply text-grey-darker

      .feature-list
        padding-left: 0rem
        list-style-type: none
        @apply w-full
        li
          @apply mb-4 text-grey-darker
          .room-card__feature-icon
            height: 18px
            width: 18px

      .more-features
        span, i
          @apply text-blue

  &__content
    &__plan
      &:not(:last-of-type)
        @apply border-b-1 border-grey-lighter
    &__amount__row
      cursor: not-allowed
  .no-event
    pointer-events: none
