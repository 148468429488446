@mixin ellipsis($line-clam: 1) {
  -webkit-line-clamp: $line-clam;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  @if $line-clam==1 {
    white-space: nowrap;
  }
  @else {
    display: -webkit-box;
  }
}

@mixin ellipsisNone() {
  // display: block;
  // white-space: pre-line;
  overflow: initial;
  white-space: initial;
  word-break: break-word;
}

@for $i from 1 through 5 {
  .ellipsis-#{$i} {
    @include ellipsis($i);
  }
}