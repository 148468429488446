.nav-dropdown {
  @apply relative flex items-stretch h-full list-reset;
}
.nav-dropdown__display {
  @apply flex content-center items-center cursor-pointer;
}
.nav-dropdown__list {
  @apply absolute bg-white shadow-lg rounded-b-sm hidden overflow-hidden;
  z-index: 11;
  min-width: 133px;
  top: 100%;
  transition: all 0.3s ease;
}
.nav-dropdown__list li {
  @apply p-16 text-center cursor-pointer;
}
.nav-dropdown__list li:hover {
  @apply bg-grey-lightest;
}
.nav-dropdown__list li.actived {
  @apply bg-blue text-white;
}
.nav-dropdown:hover > .nav-dropdown__list {
  @apply block;
}
.nav-dropdown:hover a {
  @apply text-grey-darker w-full h-full;
}
.nav-dropdown:hover a li {
  @apply w-full;
}

.multiselect__input:focus {
  @apply bg-transparent;
}
.multiselect__select:before {
  @apply text-red border-black;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.multiselect__tags {
  min-height: 36px;
  @apply bg-grey-lightest border-none rounded;
}
.multiselect__option {
  min-height: 36px;
}
.multiselect__placeholder {
  @apply text-grey-dark mb-8;
}
.multiselect__single {
  @apply bg-transparent;
}
.multiselect__content-wrapper {
  @apply bg-white shadow;
}
.multiselect__option--selected {
  @apply bg-blue text-white;
}
.multiselect__option--highlight {
  @apply bg-grey-lighter text-grey-darker;
}
.multiselect__option--highlight.multiselect__option--selected {
  @apply bg-blue text-white;
}

.popover-container label {
  @apply w-full flex items-center justify-between;
}
.popover-container input {
  @apply bg-transparent w-full;
  font-size: 16px !important;
}
.popover-container i {
  @apply text-grey;
}

.vue-numeric-input {
  @apply w-full my-4 !important;
}
.vue-numeric-input input.numeric-input {
  @apply p-8 border-0 text-center text-grey-darker bg-grey-lightest rounded-lg overflow-hidden !important;
  padding: 5px;
  pointer-events: none;
}
.vue-numeric-input button.btn {
  @apply bg-grey-lighter shadow-none border-0 cursor-pointer px-24 !important;
  border-radius: 0;
  top: 0;
}
.vue-numeric-input button.btn-decrement {
  @apply rounded-l-lg;
  left: 0;
}
.vue-numeric-input button.btn-increment {
  @apply rounded-r-lg;
  right: 0;
}
.vue-numeric-input button.btn .btn-icon {
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.vue-numeric-input button.btn .btn-icon:before, .vue-numeric-input button.btn .btn-icon:after {
  @apply bg-grey-dark !important;
}
.vue-numeric-input button.btn .btn-icon:before {
  width: 12px;
}
.vue-numeric-input button.btn .btn-icon:after {
  height: 12px;
}
.vue-numeric-input button.btn[disabled] {
  @apply bg-grey-lightest shadow-inner !important;
}
.vue-numeric-input button.btn[disabled] .btn-icon:before, .vue-numeric-input button.btn[disabled] .btn-icon:after {
  @apply bg-grey-dark !important;
}

.flex__aside-panel {
  width: auto;
}
@screen lg {
  .flex__aside-panel {
    flex-shrink: 0;
    width: 400px;
  }
}

.flex__main-panel {
  width: 100%;
}
@screen lg {
  .flex__main-panel {
    max-width: calc(100% - 400px);
  }
}

.price--cross {
  @apply relative text-grey-dark;
}
.price--cross:after {
  content: "";
  @apply absolute;
  @apply border-t-2 border-orange-dark;
  width: 120%;
  top: 50%;
  left: -10%;
  transform: rotate(-5deg) translateY(-50%);
}