
.components-room-date-selector {
  @import '@/stylesheets/bs_func.scss';
  @import '@/stylesheets/width.scss';
  @import '@/stylesheets/padding.scss';
  @import '@/stylesheets/middle.scss';
  @import '@/stylesheets/trans.scss';
  @import '@/stylesheets/ellipsis.scss';

  $color-primary: #078abc;
  $color-dark: #3c4145;
  $color-light: #f8fafc;
  $color-secondary: #a3abb2;

  .date-range-selector {
    @include row();
    border-radius: 8px;

    > div {
      @include col-auto();
      color: $color-dark;

      &.date-range-split {
        padding: 1.65em 1.2em 0;
      }

      &.room-type-split {
        padding: 1em;
      }

      &.room-type {
        @include col();
        display: inline-grid;

        > div {
          &.value {
            @include ellipsis();
          }
        }
      }

      i {
        color: $color-secondary;
      }

      > div {
        @include trans();

        &.label {
          margin-bottom: 0.5em;
        }

        &.value {
          cursor: pointer;
          font-weight: 700;
          padding-left: 0.25em;

          &:hover {
            color: $color-primary;
          }
        }
      }

      &.active {
        i {
          color: $color-dark;
        }
      }
    }
  }

  .input {
    color: $color-dark;
    background-color: $color-light;
    border-radius: 0.375rem;
    border-width: 1px;
    border-color: transparent;
    padding: 0.5rem 1rem;
  }

  .component-popper-menu.active {
    .date-range-selector,
    .dropdown {
      border-radius: 0;
    }

    // mask
    // &:after {
    //   content: "";
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100vh;
    //   background-color: rgba(0, 0, 0, 0.6);
    //   z-index: 1;
    // }
  }

  .component-popper-menu {
    .dropdown {
      .dropdown-menu {
        background-color: #fff;
        color: $color-dark;
        padding: 1em 0.5em;

        > div {
          padding: 0.5em;
        }

        .dropdown-calender {
          width: 300px;

          .calender {
            border: 0;
            width: 100%;
          }

          .c-pane-container {
            box-shadow: none !important;
            color: #393d46 !important;
          }

          .toolbar {
            display: block;
            padding-top: .5em;
            text-align: center;

            > div {
              @include middle();
              &:not(:last-child) {
                padding-bottom: 1em;
              }
            }
          }
        }

        .dropdown-room {
          position: relative;
          width: 350px;
          background-color: #fff;

          .split-bar {
            @include middle();
            position: relative;
            width: 100%;
            height: 20px;
            padding-bottom: 1em;

            &:after {
              content: "";
              width: 56px;
              height: 4px;
              background-color: #d9d9d9;
              border-radius: 4px;
            }
          }

          .dropdown-room-list {
            height: 368px;
            overflow-y: auto;

            &.disabled {
              opacity: 0.2;
              cursor: not-allowed;
              overflow: hidden;

              ul {
                pointer-events: none;
              }
            }

            .dropdown-room-list-image {
              @include middle();
              position: relative;

              .checked-icon {
                position: absolute;
                width: 1.5rem;
                height: 1.5rem;
                background-color: $color-primary;
                border-radius: 50%;
                color: #fff;
                border: 2px solid #fff;
                font-size: 80%;
                text-align: center;
                line-height: 1.3rem;
              }
            }

            .dropdown-room-list-label {
              @include ellipsis(2);
              font-size: 0.875em;
              font-weight: 500;
              line-height: 1.55em;
            }

            ul {
              list-style-type: none;
              padding: 2px 2px 2em;
              margin: 0;

              li {
                padding: 0.5em;
                border-radius: 0.5em;
                cursor: pointer;

                &:hover {
                  background-color: #f6f6f6;
                }

                &.active {
                  outline: 2px solid #3AB3E2;
                }

                &.disabled {
                  opacity: 0.2;
                  cursor: not-allowed;
                }
              }
            }
          }

          &:after {
            pointer-events: none;
            content: "";
            display: block;
            position: relative;
            left: 0;
            top: -100%;
            z-index: 1;
            width: 100%;
            height: 100%;
            box-shadow: inset 0 -2em 1em #fff;
          }

          img {
            object-fit: cover;
            width: 60px;
            height: 60px;
            min-width: 60px;
            min-height: 60px;
            border-radius: 0.5em;
          }
        }

        .dropdown-room-clear {
          white-space: nowrap;
          color: $color-primary;
          width: auto;
        }
      }

      .dropdown-room-list-scroll-to {
        @include trans();
        cursor: pointer;
        position: absolute;
        left: 50%;
        top: 100%;
        margin-left: -0.75rem;
        margin-top: -2.5rem;
        z-index: 2;
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 10px #ccc;
        font-size: 1.5rem;

        &:before {
          margin: 0;
        }

        &.scroll-bottom {
          opacity: 0;
        }
      }

      > .toolbar {
        display: none;
        box-shadow: 0 0 10px #eee;
        padding: 0.5em 0.5em 1em;
        text-align: center;

        > div {
          @include middle();
          width: 100%;
          padding: 0 .5em;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .component-popper-menu {
      .date-range-selector {
        padding: 0.75em 1em 0.75em 0.75em;
        background-color: $color-light;

        &.classic {
          &:before {
            line-height: 1;
          }

          > div {
            &.date-range-checkin,
            &.date-range-checkout {
              line-height: 1.7em;

              .label {
                display: none;
              }
            }

            &.date-range-split {
              padding: 0.15em 0.25em 0;
            }
          }
        }

        &:before {
          content: "\E862";
          font-family: owl;
          line-height: 2em;
          font-size: 1.5em;
          padding-right: 0.25em;
          color: $color-secondary;
        }

        > div {
          > div {
            &.value {
              &:hover {
                color: $color-dark !important;
              }
            }
          }

          &.date-range-checkin,
          &.date-range-checkout {
            .label {
              visibility: hidden;
            }
          }

          &.date-range-split {
            padding: 1.65em 0.25em 0;
          }

          &.room-type-split {
            display: none;
          }

          &.room-type {
            position: absolute;
            top: 1.2em;
            left: 3em;
            font-size: 0.9em;

            .label {
              display: none;
            }

            .value {
              font-weight: 500;
            }
          }
        }
      }

      .dropdown {
        .dropdown-menu {
          position: fixed;
          top: 72px;
          left: 0;
          width: 100vw !important;
          height: 100vh;
          overflow-y: auto;
          flex-direction: column;

          > div {
            padding: 1em;
          }

          .dropdown-calender,
          .dropdown-room {
            width: 100vw !important;
            position: sticky;
            top: 0;
            padding-left: 0;
          }

          .dropdown-calender {
            > div {
              width: calc(100vw - 2em);
            }

            .toolbar {
              .buttons {
                display: none;
              }
            }
          }

          .dropdown-room {
            max-width: 100%;
            box-shadow: 0 0 10px #eee;
            padding-bottom: 120px;

            .dropdown-room-list {
              height: auto;

              .dropdown-room-list-scroll-to {
                display: none;
              }
            }
          }
        }

        > .toolbar {
          display: flex;
          position: fixed;
          left: 0;
          bottom: 0;
          width: 100%;
          background-color: #fff;

          .dropdown-room-list-scroll-to {
            top: 50%;
          }
        }
      }
    }
  }
}
