
.modal__content-width-cover
  // width: 100%
  // max-width: 450px
  &__cover
    height: 170px
    @apply bg-grey-lightest bg-center bg-no-repeat bg-cover
  &__content
    max-height: 500px
    -webkit-overflow-scrolling: touch
    overflow-y: auto
    ul
      @apply pl-0
      list-style-type: none
      .modal__feature-icon
        height: 18px
        width: 18px
    &__title
      @apply flex flex-wrap items-center
      &__iconStyle
        @apply mr-4 text-2xl inline-block
        &.success
          @apply text-green
        &.warning
          @apply text-orange
        &.error
          @apply text-red
