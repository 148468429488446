
.devTool
  @apply fixed bg-grey-lightest rounded ease
  z-index: 99999
  bottom: 0
  min-width: 360px
  right: 100%
  &.opend
    right: calc(100% - 360px)
  .title
    @apply text-right px-16 pt-4 text-grey uppercase text-xs
  a
    @apply inline-block py-8 px-16 text-grey-dark no-underline ease
    &:hover
      @apply text-grey-darker
    &.router-link-exact-active
      @apply text-blue font-bold
.openBtn
  @apply absolute bg-red p-4 rounded-r text-white cursor-pointer
  top: 0
  right: -26px
  i:before
    @apply ease
  &.isOpen i:before
    transform: rotate(-180deg)
