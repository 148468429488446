/*ease/linear/ease-in/ease-out/ease-in-out*/
@mixin trans($trans-s: 0.3s, $trans-m: ease-out, $target: all) {
  -webkit-transition: $target $trans-s $trans-m;
  /* Safari */
  -moz-transition: $target $trans-s $trans-m;
  -o-transition  : $target $trans-s $trans-m;
  transition     : $target $trans-s $trans-m;
}

.trans {
  @include trans();
}

.transD5 {
  @include trans(.5s);
}

@mixin trans_none() {
  -webkit-transition: none !important;
  /* Safari */
  -moz-transition: none !important;
  -o-transition  : none !important;
  transition     : none !important;
}

.trans_none {
  @include trans_none();
}
